import React, { useState, useEffect, useCallback } from "react";
import { useWallet, useConnection } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import {
  getCreatorDashboard,
  createProgram,
  updateProgram,
  deleteProgram,
  initiateRaid,
  sendPvpRequest,
  getPvpRequests,
  respondToPvpRequest,
} from "../utils/api";
import BlinkCard from "./BlinkCard";
import {
  Connection,
  PublicKey,
  Transaction,
  SystemProgram,
  TransactionInstruction,
  SYSVAR_RENT_PUBKEY,
} from "@solana/web3.js";
import {
  TOKEN_PROGRAM_ID,
  ASSOCIATED_TOKEN_PROGRAM_ID,
  getAssociatedTokenAddress,
  createAssociatedTokenAccountInstruction,
  getAccount,
} from "@solana/spl-token";
import BN from "bn.js";
import { encode, decode } from "bs58";

// Import your program ID
const PROGRAM_ID = new PublicKey(
  "FTDJxoE7ZMrKu2kW3KEmhSCvqXegUugnPPYVMQThvqY9",
);
const REQUIRED_STAKE_AMOUNT = 1000 * 1000000000; // 1000 tokens with 9 decimals

function CreatorDashboard() {
  const { publicKey, sendTransaction, signTransaction, connected } =
    useWallet();
  const { connection } = useConnection();
  const [dashboardData, setDashboardData] = useState(null);
  const [pvpRequests, setPvpRequests] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isCreating, setIsCreating] = useState(false);
  const [editingProgram, setEditingProgram] = useState(null);
  const [initiatingRaid, setInitiatingRaid] = useState(null);
  const [raidType, setRaidType] = useState("");
  const [pvpOpponent, setPvpOpponent] = useState("");

  useEffect(() => {
    if (publicKey) {
      fetchDashboardData();
    }
  }, [publicKey]);

  const fetchDashboardData = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const [dashboardData, pvpRequestsData] = await Promise.all([
        getCreatorDashboard(publicKey.toBase58()),
        getPvpRequests(),
      ]);
      setDashboardData(dashboardData);
      setPvpRequests(pvpRequestsData.pvp_requests);
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateProgram = useCallback(
    async (newProgram) => {
      if (!connected || !publicKey) {
        setError("Please connect your wallet first");
        return;
      }

      setIsCreating(true);
      setError(null);

      try {
        // First, create program in Django backend
        const formData = new FormData();
        formData.append("name", newProgram.name);
        formData.append("description", newProgram.description);
        if (newProgram.profile_picture) {
          formData.append("profile_picture", newProgram.profile_picture);
        }

        const createdProgram = await createProgram(formData);

        // Now create the program on-chain
        const transaction = await createRaidProgramOnChain(createdProgram);

        // Sign the transaction
        const signedTransaction = await signTransaction(transaction);

        // Send the signed transaction
        const signature = await sendTransaction(signedTransaction, connection);

        // Wait for confirmation
        const confirmation = await connection.confirmTransaction(
          signature,
          "confirmed",
        );

        if (confirmation.value.err) {
          throw new Error(
            `Transaction failed: ${JSON.stringify(confirmation.value.err)}`,
          );
        }

        console.log("Program created on-chain with signature:", signature);

        // Refresh dashboard data to include the new program
        await fetchDashboardData();
      } catch (err) {
        console.error("Error creating program:", err);
        setError(`Failed to create program: ${err.message}`);
      } finally {
        setIsCreating(false);
      }
    },
    [connected, publicKey, connection, signTransaction, sendTransaction],
  );

  const createRaidProgramOnChain = async (program) => {
    try {
      const encoder = new window.TextEncoder(); // Ensure it's using the global TextEncoder
      const [tokenMintPda] = await PublicKey.findProgramAddress(
        [encoder.encode("pda_token_mint")],
        PROGRAM_ID,
      );

      const userTokenAccount = await getAssociatedTokenAddress(
        tokenMintPda,
        publicKey,
      );

      const raidProgramSeed = `raid_program_${publicKey.toBase58()}_${program.name}`;
      const [raidProgramAccount] = await PublicKey.findProgramAddress(
        [encoder.encode(raidProgramSeed)],
        PROGRAM_ID,
      );

      // Check user token account and balance
      let userTokenAccountInfo;
      try {
        userTokenAccountInfo = await getAccount(connection, userTokenAccount);
        if (userTokenAccountInfo.amount < REQUIRED_STAKE_AMOUNT) {
          throw new Error(
            `Insufficient balance. Required: ${REQUIRED_STAKE_AMOUNT / 1000000000} tokens`,
          );
        }
      } catch (error) {
        if (error.name === "TokenAccountNotFoundError") {
          throw new Error(
            "User token account not found. Please initialize it first.",
          );
        }
        throw error;
      }

      const instructionData = new Uint8Array([
        2, // Instruction discriminator for CreateRaidProgram
        ...new TextEncoder().encode(program.name.padEnd(32, "\0")),
        ...new TextEncoder().encode(program.description.padEnd(64, "\0")),
        ...new TextEncoder().encode(
          `https://bullposter.xyz/media/program_pfp/${program.name}.png`.padEnd(
            128,
            "\0",
          ),
        ),
        ...new BN(REQUIRED_STAKE_AMOUNT).toArray("le", 8),
      ]);

      const initializeRaidProgramIx = new TransactionInstruction({
        keys: [
          { pubkey: publicKey, isSigner: true, isWritable: true },
          { pubkey: userTokenAccount, isSigner: false, isWritable: true },
          { pubkey: raidProgramAccount, isSigner: false, isWritable: true },
          { pubkey: tokenMintPda, isSigner: false, isWritable: false },
          { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
          {
            pubkey: SystemProgram.programId,
            isSigner: false,
            isWritable: false,
          },
          { pubkey: SYSVAR_RENT_PUBKEY, isSigner: false, isWritable: false },
        ],
        programId: PROGRAM_ID,
        data: instructionData,
      });

      const transaction = new Transaction().add(initializeRaidProgramIx);
      transaction.feePayer = publicKey;
      const { blockhash } = await connection.getLatestBlockhash();
      transaction.recentBlockhash = blockhash;

      return transaction;
    } catch (err) {
      console.error("Error creating Raid Program on-chain:", err);
      throw err;
    }
  };

  const handleUpdateProgram = async (updatedProgram) => {
    try {
      await updateProgram(updatedProgram.id, updatedProgram);
      fetchDashboardData();
      setEditingProgram(null);
    } catch (err) {
      setError(err.message);
    }
  };

  const handleDeleteProgram = async (programId) => {
    try {
      await deleteProgram(programId);
      fetchDashboardData();
    } catch (err) {
      setError(err.message);
    }
  };

  const handleInitiateRaid = async (programId) => {
    if (raidType === "pvp") {
      if (!pvpOpponent) {
        setError("Please select a PVP opponent");
        return;
      }
      try {
        await sendPvpRequest({
          challenger_program_id: programId,
          challenged_public_key: pvpOpponent,
        });
        setError("PVP request sent successfully");
      } catch (err) {
        setError(err.message);
      }
    } else {
      try {
        await initiateRaid(programId, { competition_type: raidType });
        fetchDashboardData();
      } catch (err) {
        setError(err.message);
      }
    }
    setInitiatingRaid(null);
    setRaidType("");
    setPvpOpponent("");
  };

  const handleRespondToPvpRequest = async (requestId, response) => {
    try {
      await respondToPvpRequest(requestId, { response });
      fetchDashboardData();
    } catch (err) {
      setError(err.message);
    }
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">Error: {error}</div>;
  }

  return (
    <div className="space-y-8">
      <h2 className="text-3xl font-bold mb-6">Creator Dashboard</h2>

      {error && (
        <div
          className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative"
          role="alert"
        >
          <strong className="font-bold">Error:</strong>
          <span className="block sm:inline"> {error}</span>
        </div>
      )}

      {!connected ? (
        <div className="text-center">
          <p className="mb-4">
            Please connect your wallet to create or manage programs.
          </p>
          <WalletMultiButton />
        </div>
      ) : (
        <>
          {isLoading ? (
            <div className="text-center">
              <div className="spinner"></div>
              <p>Loading your programs...</p>
            </div>
          ) : (
            <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
              <h3 className="text-xl font-semibold mb-4 text-green-400">
                Your Programs
              </h3>
              {dashboardData && dashboardData.programs.length === 0 ? (
                <p className="text-gray-300">
                  You haven't created any programs yet.
                </p>
              ) : (
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
                  {dashboardData &&
                    dashboardData.programs.map((program) => {
                      const actionApiUrl = `https://bullposter.xyz/actions/program-card?programId=${program.id}`;
                      return (
                        <div key={program.id}>
                          <ProgramCard
                            program={program}
                            onEdit={() => setEditingProgram(program)}
                            onDelete={() => handleDeleteProgram(program.id)}
                            onInitiateRaid={() => setInitiatingRaid(program)}
                            pvpRequests={pvpRequests.filter(
                              (req) => req.challenged_program_id === program.id,
                            )}
                            onRespondToPvpRequest={handleRespondToPvpRequest}
                            actionApiUrl={actionApiUrl}
                          />
                          <div className="mt-4">
                            <h4 className="text-lg font-semibold mb-2 text-green-400">
                              Program Card
                            </h4>
                            <BlinkCard actionApiUrl={actionApiUrl} />
                            <div className="mt-2">
                              <input
                                type="text"
                                value={actionApiUrl}
                                readOnly
                                className="w-full p-2 bg-gray-600 text-white rounded"
                              />
                              <button
                                onClick={() =>
                                  navigator.clipboard.writeText(actionApiUrl)
                                }
                                className="w-full mt-2 bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded"
                              >
                                Copy URL
                              </button>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
            </div>
          )}

          {!isCreating && !editingProgram && (
            <button
              onClick={() => setIsCreating(true)}
              className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
            >
              Create New Program
            </button>
          )}

          {(isCreating || editingProgram) && (
            <ProgramForm
              initialData={editingProgram}
              onSubmit={isCreating ? handleCreateProgram : handleUpdateProgram}
              onCancel={() => {
                setIsCreating(false);
                setEditingProgram(null);
              }}
            />
          )}

          {initiatingRaid && (
            <div className="bg-gray-700 rounded-lg p-6 shadow-lg mt-8">
              <h3 className="text-xl font-semibold mb-4 text-green-400">
                Initiate Raid
              </h3>
              <select
                value={raidType}
                onChange={(e) => setRaidType(e.target.value)}
                className="w-full p-2 mb-4 bg-gray-600 text-white rounded"
              >
                <option value="">Select Raid Type</option>
                <option value="4-program">4 Programs</option>
                <option value="6-program">6 Programs</option>
                <option value="12-program">12 Programs</option>
                <option value="24-program">24 Programs</option>
                <option value="pvp">PVP</option>
              </select>
              {raidType === "pvp" && (
                <input
                  type="text"
                  value={pvpOpponent}
                  onChange={(e) => setPvpOpponent(e.target.value)}
                  placeholder="Enter opponent's public key"
                  className="w-full p-2 mb-4 bg-gray-600 text-white rounded"
                />
              )}
              <button
                onClick={() => handleInitiateRaid(initiatingRaid.id)}
                className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
                disabled={initiatingRaid.is_conducting_raid}
              >
                Initiate Raid
              </button>
              <button
                onClick={() => {
                  setInitiatingRaid(null);
                  setRaidType("");
                  setPvpOpponent("");
                }}
                className="ml-4 bg-red-500 hover:bg-red-600 text-white font-bold py-2 px-4 rounded transition duration-300"
              >
                Cancel
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
}

function ProgramCard({
  program,
  onEdit,
  onDelete,
  onInitiateRaid,
  pvpRequests,
  onRespondToPvpRequest,
  actionApiUrl,
}) {
  return (
    <div className="bg-gray-700 rounded-lg p-4 shadow-lg">
      <h4 className="text-lg font-semibold text-white">{program.name}</h4>
      <p className="text-gray-300 text-sm mb-2">{program.description}</p>
      <p className="text-sm text-gray-400 mb-1">Size: {program.size}</p>
      <p className="text-sm text-gray-400 mb-2">
        Rewards Distributed: {program.total_rewards_distributed} SOL
      </p>
      <div className="flex justify-end space-x-2">
        <button
          onClick={onEdit}
          className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-1 px-2 rounded text-sm transition duration-300"
        >
          Edit
        </button>
        <button
          onClick={onDelete}
          className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-sm transition duration-300"
        >
          Delete
        </button>
        <button
          onClick={onInitiateRaid}
          className="bg-purple-500 hover:bg-purple-600 text-white font-bold py-1 px-2 rounded text-sm transition duration-300"
          disabled={program.is_conducting_raid}
        >
          {program.is_conducting_raid ? "Raid in Progress" : "Initiate Raid"}
        </button>
      </div>
      {pvpRequests.length > 0 && (
        <div className="mt-4">
          <h5 className="text-sm font-semibold text-green-400">
            Pending PVP Requests:
          </h5>
          {pvpRequests.map((request) => (
            <div key={request.id} className="mt-2 p-2 bg-gray-600 rounded">
              <p className="text-sm text-white">
                From: {request.challenger.slice(0, 4)}...
                {request.challenger.slice(-4)}
              </p>
              <div className="mt-2 flex space-x-2">
                <button
                  onClick={() => onRespondToPvpRequest(request.id, "accept")}
                  className="bg-green-500 hover:bg-green-600 text-white font-bold py-1 px-2 rounded text-xs transition duration-300"
                >
                  Accept
                </button>
                <button
                  onClick={() => onRespondToPvpRequest(request.id, "reject")}
                  className="bg-red-500 hover:bg-red-600 text-white font-bold py-1 px-2 rounded text-xs transition duration-300"
                >
                  Reject
                </button>
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-4">
        <BlinkCard actionApiUrl={actionApiUrl} />
      </div>
    </div>
  );
}

function ProgramForm({ initialData, onSubmit, onCancel }) {
  const [formData, setFormData] = useState(
    initialData || { name: "", description: "" },
  );

  const [profilePicture, setProfilePicture] = useState(null);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleFileChange = (e) => {
    setProfilePicture(e.target.files[0]);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const formDataToSubmit = new FormData();
    formDataToSubmit.append("name", formData.name);
    formDataToSubmit.append("description", formData.description);
    if (profilePicture) {
      formDataToSubmit.append("profile_picture", profilePicture);
    }
    onSubmit(formDataToSubmit);
  };

  return (
    <div className="bg-gray-700 rounded-lg p-6 shadow-lg mt-8">
      <h3 className="text-xl font-semibold mb-4 text-green-400">
        {initialData ? "Edit Program" : "Create New Program"}
      </h3>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300">
            Program Name
          </label>
          <input
            type="text"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-600 border-gray-500 text-white"
            required
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300">
            Profile Picture
          </label>
          <input
            type="file"
            name="profile_picture"
            onChange={handleFileChange}
            className="mt-1 block w-full text-white"
          />
        </div>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-300">
            Description
          </label>
          <textarea
            name="description"
            value={formData.description}
            onChange={handleChange}
            className="mt-1 block w-full rounded-md bg-gray-600 border-gray-500 text-white"
            rows="3"
            required
          ></textarea>
        </div>
        <div className="flex justify-end space-x-4">
          <button
            type="button"
            onClick={onCancel}
            className="bg-gray-500 hover:bg-gray-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            Cancel
          </button>
          <button
            type="submit"
            className="bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          >
            {initialData ? "Update Program" : "Create Program"}
          </button>
        </div>
      </form>
    </div>
  );
}

export default CreatorDashboard;
