import React, { useState, useEffect } from "react";
import { getUserData, updateUserProfile } from "../utils/api";
import BlinkCard from "./BlinkCard";

const Profile = () => {
  const [userData, setUserData] = useState(null);
  const [email, setEmail] = useState("");
  const [twitter, setTwitter] = useState("");
  const [profilePicture, setProfilePicture] = useState(null);
  const [profilePicturePreview, setProfilePicturePreview] = useState(null);
  const [dob, setDob] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    setIsLoading(true);
    try {
      const data = await getUserData();
      setUserData(data);
      setEmail(data.email || "");
      setTwitter(data.twitter || "");
      setDob(data.dob || "");

      const profilePicUrl = `https://bullposter.xyz/media/user_pfp/${data.username}.png`;
      const response = await fetch(profilePicUrl);
      if (response.ok) {
        setProfilePicturePreview(profilePicUrl);
      } else {
        setProfilePicturePreview(
          "https://bullposter.xyz/media/user_pfp/default.png",
        );
      }
    } catch (err) {
      setError("Failed to fetch user data");
    } finally {
      setIsLoading(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type === "image/png") {
      setProfilePicture(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicturePreview(reader.result);
      };
      reader.readAsDataURL(file);
    } else {
      setError("Only PNG images are allowed");
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      await updateUserProfile({
        email,
        twitter,
        dob,
        profilePicture,
      });
      setUserData({
        ...userData,
        email,
        twitter,
        dob,
      });
      setError(null);
    } catch (err) {
      setError("Failed to update profile");
    } finally {
      setIsLoading(false);
    }
  };

  if (isLoading) {
    return <div className="text-center">Loading...</div>;
  }

  if (error) {
    return <div className="text-center text-red-500">{error}</div>;
  }

  const userCardApiUrl = `https://bullposter.xyz/actions/user-card?userId=${userData.id}`;
  console.log("User Card API URL:", userCardApiUrl); // Add logging
  return (
    <div className="bg-gray-800 rounded-lg p-6 shadow-lg">
      <h2 className="text-2xl font-bold mb-4 text-green-400">
        Profile Settings
      </h2>
      <div className="mt-8">
        <h3 className="text-xl font-semibold mb-4 text-green-400">User Card</h3>
        <BlinkCard actionApiUrl={userCardApiUrl} />
      </div>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div>
          <label
            htmlFor="email"
            className="block text-sm font-medium text-gray-300"
          >
            Email
          </label>
          <input
            type="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-green-500 focus:ring-green-500"
            required
          />
        </div>
        <div>
          <label
            htmlFor="twitter"
            className="block text-sm font-medium text-gray-300"
          >
            Twitter Handle
          </label>
          <input
            type="text"
            id="twitter"
            value={twitter}
            onChange={(e) => setTwitter(e.target.value)}
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <div>
          <label
            htmlFor="dob"
            className="block text-sm font-medium text-gray-300"
          >
            Date of Birth
          </label>
          <input
            type="date"
            id="dob"
            value={dob}
            onChange={(e) => setDob(e.target.value)}
            className="mt-1 block w-full rounded-md bg-gray-700 border-gray-600 text-white focus:border-green-500 focus:ring-green-500"
          />
        </div>
        <div className="flex items-center">
          {profilePicturePreview && (
            <div className="mr-4">
              <img
                src={profilePicturePreview}
                alt="Profile Preview"
                className="w-24 h-24 object-cover rounded-full"
              />
            </div>
          )}
          <div>
            <label
              htmlFor="profile_picture"
              className="block text-sm font-medium text-gray-300"
            >
              Profile Picture
            </label>
            <input
              type="file"
              id="profile_picture"
              accept="image/png"
              onChange={handleFileChange}
              className="hidden"
            />
            <label
              htmlFor="profile_picture"
              className="mt-1 block w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded cursor-pointer text-center"
            >
              Choose Image
            </label>
          </div>
        </div>
        <button
          type="submit"
          className="w-full bg-green-500 hover:bg-green-600 text-white font-bold py-2 px-4 rounded transition duration-300"
          disabled={isLoading}
        >
          {isLoading ? "Updating..." : "Update Profile"}
        </button>
      </form>
    </div>
  );
};

export default Profile;
